<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <v-card-title class="headline">Clone {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Clone/duplicate this event to create either a test or a next edition of it. Cloning will duplicate the configuration but will not copy any of the participants, teams or results to the clone.
        </p>
        <p>
          Note: daily or weekly leaderboards will have to be deleted and regenerated to align them with the new event dates after the event is cloned.
        </p>

        <h3>New Name</h3>
        <v-form :lazy-validation="false" ref="form">
          <p>
            <v-text-field 
              v-model="request.name"
              :rules="nameRules"
              label="New Name"
              />
          </p>

          <h3>New Dates</h3>
          <p>Select the event window for the clone. Only activities between open and close dates may count for this event. You may start inviting users to join before the start date and keep access to the data after the close date.</p>
          <v-row>
            <v-col cols="12" md="6" class="">
              <DateWithTimeZonePicker v-model="request.from" :timeZone="event.timezone" label="Start date (included)" />
            </v-col>
            <v-col cols="12" md="6" class="">
              <DateWithTimeZonePicker v-model="request.till" :minDate="request.from" :timeZone="event.timezone" label="Last date (included)" />
            </v-col>
          </v-row>

          <div v-if="event.races.some(x=>x.team)">
            <h3>Clone Teams</h3>
            <v-switch v-model="request.clone_teams" label="Also copy all teams over" />

            <v-checkbox 
              v-for="(race,idx) in event.races.filter(x=>x.team_category)" 
              v-model="request.clone_team_categories" 
              :key="idx" 
              :label="`Also copy teams of category ${race.team_category}`"
              :value="race.team_category"
              :true-value="race.team_category"
              :false-value="null" />
          </div>

          <p>
            <v-btn :disabled="!canClone" color="primary" large @click="clone" :loading="$store.getters.isLoading">Clone</v-btn>
          </p>
        </v-form>
      </v-card-text>


    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventLock",
  components: {
    DateWithTimeZonePicker,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      event: null,
      nameRules: [
        v => !!v || 'Please enter the name of the event',
        v => v != this.event.name || 'Please enter a different name than the current edition',
      ],

      request: {
        name: null,
        from: null,
        till: null,
        clone_team_categories: [],
      }
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
      this.request.name = this.event.name;
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async clone() {
//      console.log('request', this.request);
      if (this.$refs.form.validate()) {
        const response = (await eventManagerService.duplicate(this.event.id, this.request.name, this.request)).data;
        if (response.id) {
          this.eventUtil.event = response;
          this.eventUtil.refresh();
          this.$router.push({ name: 'eventmanagerView', params: { id: response.id } });
          //await this.getProfile();
        }
      }
    },

  },
  computed: {
    canClone() {
      return this.request.from && this.request.till && this.request.name;
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Clone', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event);
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

